export default {
    computed: {
        allPossibleParticipants() {
            if (!this.includeIneligibleParticipants) {
                return this.participants.sort((a, b) => a.name.localeCompare(b.name));
            }
            return this.participants.concat(this.ineligibleParticipants).sort((a, b) => a.name.localeCompare(b.name));
        },
    },
    methods: {
        currencyForParticipant(participant) {
            const currency = this.currencies.find(c => String(c.currency) === String(participant.currency));
            if (!currency) {
                alert('Currency not found');
            }
            return currency.rate;
        },
        commentsForParticipant(participant) {
            return this.comments[participant.id] || [];
        },
        submissionsForParticipant(participant) {
            return this.participantSubmissions[participant.id] || [];
        },
    },
};