<template>
  <tr class="bg-gray-50">
    <!-- Employee basic details  -->
    <td class="sticky left-0 whitespace-nowrap px-4 py-2 font-medium text-gray-900 bg-gray-50">
      <ParticipantBasicDetails :participant="participant" />
    </td>

    <!-- Current Total Cash -->
    <td class="whitespace-nowrap px-4 py-2">
      {{currency}}{{new Intl.NumberFormat('en-US').format(Math.round(totalCash))}}
      <p class="text-xs" v-if="variableSalary">
        {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(baseSalary))}} + {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(variableSalary))}}
      </p>
      <p class="text-xs" v-if="variableSalary">
        <span>
          Base: {{(100.0 - Number(currentVariablePercentage)).toFixed(0)}}% <br />
          Variable: {{Number(currentVariablePercentage).toFixed(0)}}%
        </span>
      </p>
    </td>

    <!-- Compa Ratio -->
    <td class="whitespace-nowrap text-center px-4 py-2">
      <div v-if="salaryBenchmark && salaryBenchmark > 0">
        <div class="flex flex-col space-y-2 p-2 w-32" v-if="participant.compa_ratio <= compaRange[1] && participant.compa_ratio >= compaRange[0]">
          <input type="range" class="w-full accent-teal-400 cursor-not-allowed" :min="compaRange[0]" :max="compaRange[1]" step="0.1" v-model="compaRangeSlider" />
        </div>

        <div class="flex flex-col space-y-2 p-2 w-32" v-else>
          <input type="range" class="w-full accent-red-400 cursor-not-allowed" :min="compaRange[0]" :max="compaRange[1]" step="0.1" v-model="compaRangeSlider" />
        </div>
        {{Number(participant.compa_ratio).toFixed(2)}}
      </div>
      <span v-else> N/A </span>
    </td>

    <!-- Benchmark Range -->
    <td class="whitespace-nowrap text-center">
      <div v-if="salaryBenchmark && salaryBenchmark > 0">
        <p>{{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(benchmarkRange[0] / 100) * 100)}} - {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(benchmarkRange[1] / 100) * 100)}}</p>
        <p class="text-xs" v-if="adjustBenchmarks == 'No'">
          Median: {{currency}}{{new Intl.NumberFormat('en-US').format(Math.ceil(salaryBenchmark / 100) * 100)}}
        </p>
      </div>
      <p v-else class="text-center">
        N/A
      </p>
    </td>

    <!-- Suggested % Increase -->
    <td class="whitespace-nowrap px-4 py-2 text-center">
    </td>

    <!-- Base Salary % Increase -->
    <td class="whitespace-nowrap text-center">
    </td>

    <!-- Variable Pay % Increase -->
    <td v-if="!nobodyHasVariablePay" class="whitespace-nowrap text-center">
    </td>

    <!-- Total % Increase -->
    <td  v-if="!nobodyHasVariablePay" class="whitespace-nowrap text-center">
    </td>

    <!-- New Total Cash -->
    <td class="whitespace-nowrap text-center">
    </td>

    <!-- New Compa -->
    <td class="whitespace-nowrap text-center">
    </td>

    <!-- Actions -->
    <td class="whitespace-nowrap px-4 py-2">
    </td>
  </tr>


</template>

<script>
import ParticipantBasicDetails from './ParticipantBasicDetails.vue'

export default {
  name: "IneligibleParticipantDetail",
  components: {
    ParticipantBasicDetails
  },
  props: {
    participant: Object,
    useLocalCurrency: Boolean,
    adjustBenchmarks: String,
    nobodyHasVariablePay: Boolean,
    currencyRate: String,
  },
  data() {
    return {
      showPayDetails: false,
      compaRangeSlider: this.participant.compa_ratio
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.setParamsFromUrl(window.location.href);
    })
  },
  computed: {
    // These are computed properties that are not dependent on the baseSalaryPercentageIncrease or variablePayPercentageIncrease
    calculatedFromBase() {
      return this.participant.calculate_from_base
    },
    baseSalary() {
      if (this.useLocalCurrency) {
        return Number(this.participant.base_salary)
      } else {
        return Number(this.participant.base_salary_cycle_currency)
      }
    },
    variableSalary() {
      if (this.useLocalCurrency) {
        return Number(this.participant.variable_pay)
      } else {
        return Number(this.participant.variable_pay_cycle_currency)
      }
    },
    totalCash() {
      return this.baseSalary + this.variableSalary
    },
    currentVariablePercentage() {
      if (this.calculatedFromBase) {
        return (this.variableSalary / this.baseSalary * 100).toFixed(1)
      }

      return (this.variableSalary / this.totalCash * 100).toFixed(1)
    },
    salaryBenchmark() {
      if (this.participant.benchmark_in_local_currency == null) {
        return null
      }
      if (this.useLocalCurrency) {
        return Math.ceil(this.participant.benchmark_in_local_currency)
      } else {
        return Math.ceil(this.participant.benchmark_in_cycle_currency / 100) * 100
      }
    },
    currency() {
      if (this.useLocalCurrency) {
        return this.participant.currency_symbol
      } else {
        return "€"
      }
    },
    compaRange() {
      if (this.participant.performance_indicator == null || this.adjustBenchmarks == "No") {
        return [0.8, 1.2]
      }

      if (this.participant.performance_indicator == "1" || this.participant.performance_indicator == "2" || this.participant.performance_indicator == "Developing" || this.participant.performance_indicator == "Needs Improvement" || this.participant.performance_indicator == "Partly Performing") {
        return [0.8, 0.9]
      }

      if (this.participant.performance_indicator == "3" || this.participant.performance_indicator == "Performing" || this.participant.performance_indicator == "Fully Performing") {
        return [0.9, 1.1]
      }

      if (this.participant.performance_indicator == "4" || this.participant.performance_indicator == "Exceeding") {
        return [1.1, 1.2]
      }

      return [0.8, 1.2]
    },
    benchmarkRange() {
      if (this.participant.performance_indicator == null || this.adjustBenchmarks == "No") {
        return [this.salaryBenchmark * 0.8, this.salaryBenchmark * 1.2]
      }

      if (this.participant.performance_indicator == "1" || this.participant.performance_indicator == "2" || this.participant.performance_indicator == "Developing" || this.participant.performance_indicator == "Needs Improvement" || this.participant.performance_indicator == "Partly Performing") {
        return [(this.salaryBenchmark * 0.8).toFixed(), (this.salaryBenchmark * 0.9).toFixed()]
      }

      if (this.participant.performance_indicator == "3" || this.participant.performance_indicator == "Performing" || this.participant.performance_indicator == "Fully Performing") {
        return [(this.salaryBenchmark * 0.9).toFixed(), (this.salaryBenchmark * 1.1).toFixed()]
      }

      if (this.participant.performance_indicator == "4" || this.participant.performance_indicator == "Exceeding") {
        return [(this.salaryBenchmark * 1.1).toFixed(), (this.salaryBenchmark * 1.2).toFixed()]
      }

      return [null,null]
    },
    // End of computed properties that are not dependent on the baseSalaryPercentageIncrease or variablePayPercentageIncrease
  },
  methods: {
    setParamsFromUrl(url) {
      const currentParams = (new URL(url)).searchParams
      const participantParam = currentParams.get('participant')
      if (participantParam == this.participant.id) {
        this.showModal = true
      }
    },

    toggleShowDetails() {
      this.showPayDetails = !this.showPayDetails
    },
  },
  watch: {
    compaRangeSlider() {
      this.compaRangeSlider = this.participant.compa_ratio
    },
  }
}
</script>

