<template>
  <div>
    <span @click="toggleShowDetails" class="cursor-pointer no-underline hover:underline hover:text-orange-600">
      {{ participant.name }}
    </span>
    <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Job Title: </span> {{ participant.job_title }}</p>
    <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Start Date: </span> {{ participant.start_date }}</p>
    <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold" v-if="participant.performance_indicator">Current ePI: </span>{{ performanceIndicatorMapper(participant.performance_indicator) }}</p>

    <p v-if="showPayDetails" class="w-full">
      <p v-if="participant.previous_performance_indicator" class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Previous ePI: </span>{{ performanceIndicatorMapper(participant.previous_performance_indicator) }}</p>
      <p v-if="participant.previous_performance_indicator" class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Previous ePI Date: </span>{{ participant.previous_performance_indicator_review_period }}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Grade: </span>{{ participant.grade }}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Tenure: </span>{{ participant.tenure }}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Department: </span>{{ participant.department }}</p>
      <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Division: </span>{{ participant.division }}</p>

      <span v-if="participant.last_increase_reason && participant.last_increase_reason != 'None'" >
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Previous Increase Reason: </span>{{ participant.last_increase_reason }}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Last Increase At: </span>{{ participant.last_increase_at  }}</p>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Last Increase: </span>{{ parseFloat(participant.last_increase_percentage).toFixed(2) }}%</p>
      </span>

      <span v-else>
        <p class="text-xs text-gray-600 whitespace-no-wrap"><span class="font-bold">Last Increase: </span> None</p>
      </span>

    </p>
  </div>
</template>

<script>
export default {
  name: "ParticipantBasicDetails",
  props: {
    participant: Object
  },
  data() {
    return {
      showPayDetails: false
    }
  },
  methods: {
    toggleShowDetails() {
      this.showPayDetails = !this.showPayDetails
    },
    performanceIndicatorMapper(performance_indicator) {
      if (performance_indicator === '1') {
        return 'Needs Improvement'
      } else if (performance_indicator === '2') {
        return 'Partly Performing'
      } else if (performance_indicator === '3') {
        return 'Fully Performing'
      } else if (performance_indicator === '4') {
        return 'Exceeding'
      } else {
        return performance_indicator
      }
    }
  }
}
</script>