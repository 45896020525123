<template>
  <div v-if="visible" ref="modal" class="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75" @keydown.esc="closeModal" tabindex="0">
    <div class="bg-white p-6 rounded-lg shadow-lg" style="width: 600px; height: 450px;">
      <h2 class="text-xl mb-4">Send a Message</h2>
      <div class="mb-2">To:
        <select v-model="receivers" class="border-1 cursor-pointer border-gray-400">
          <option>To Myself</option>
          <option>All Planners</option>
          <option>Pending Approvers</option>
          <option>Pending Submissions</option>
          <option>Planners with Exceptions</option>
        </select>
        <span v-if="receiverCount" class="ml-3 text-xs">{{receiverCount}} recipients</span>
      </div>

      <textarea v-model="message" class="w-full p-2 border rounded" rows="10" placeholder="Type your message..."></textarea>
      <div class="mt-4 flex justify-end">
        <button @click="sendMessage" class="bg-blue-500 text-white px-4 py-2 rounded">Send</button>
        <button @click="closeModal" class="ml-2 bg-gray-500 text-white px-4 py-2 rounded">Cancel</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MessageModal",
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    plannerFilter: {
      type: String,
      required: true
    },
    cycleId: {
      type: Number,
      required: true
    },
    planners: {
      type: Array,
      required: true
    },
    participants: {
      type: Array,
      required: true
    },
    approvals: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      message: '',
      receivers: 'All Planners'
    }
  },
  computed: {
    receiverCount() {
      if (this.receivers === 'All Planners') {
        return this.planners.length
      } else if (this.receivers === 'Pending Approvers') {
        return this.uniquePendingApprovers.length
      } else if (this.receivers === 'Pending Submissions') {
        return this.planners.filter(planner => planner.status === 'requested').length
      } else if (this.receivers === 'Planners with Exceptions') {
        return this.uniquePlannersWithParticipantsWithExceptions.length
      } else if (this.receivers === 'To Myself') {
        return 1
      }
      return false
    },
    uniquePendingApprovers() {
      return [...new Set(this.approvals.filter(approver => approver.status === 'requested').map(approver => approver.eeid))]
    },
    plannersWithParticipantsWithExceptions() {
      return this.participants.filter(participant => {
        const hasIncrease = participant.total_percentage_increase > 0;
        const exceedsMaxRecommendation = participant.total_percentage_increase > (participant.max_recommendation + 0.4);
        const exceedsCompaRatio = participant.new_compa_ratio > 1.2;
        const variablePercentageChanged = hasIncrease && participant.current_variable_percentage != participant.new_variable_percentage;

        return exceedsMaxRecommendation || exceedsCompaRatio || variablePercentageChanged;
      }).map(participant => participant.reports_to_eeid)
    },
    uniquePlannersWithParticipantsWithExceptions() {
      return [...new Set(this.plannersWithParticipantsWithExceptions)]
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.$refs.modal.focus();
        });
      }
    }
  },
  methods: {
    async sendMessage() {
      await fetch(`/compensation_cycles/${this.cycleId}/notification_requests`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
        },
        body: JSON.stringify({
          notification_request: {recipients: this.receivers, message: this.message}
        })
      })
      this.message = ''
      this.closeModal()
    },
    closeModal() {
      this.$emit('close');
    }
  }
}
</script>