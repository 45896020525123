<template>
  <div>
    <div class="mb-1">{{totalPlanners}} planners <span class="text-xs">{{totalSubmittedPlanners}} submitted - {{totalApprovedPlanners}} approved</span></div>

    <div class="relative pt-1">
      <div class="cursor-pointer overflow-hidden h-4 mb-4 text-xs flex rounded-xl bg-amber-400">
        <div v-tippy="`${percentagePendingPlanners.toFixed(2)}% pending`" :style="`width: ${percentagePendingPlanners}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-teal-100"></div>
        <div v-tippy="`${percentageSubmittedPlanners.toFixed(2)}% submitted but not approved`" :style="`width: ${percentageSubmittedPlanners}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-300"></div>
        <div v-tippy="`${percentageApprovedPlanners.toFixed(2)}% approved`" :style="`width: ${percentageApprovedPlanners}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center bg-teal-500"></div>
      </div>
    </div>

    <div class="mb-1" >
      {{totalParticipants}} participants <span class="text-xs">{{totalParticipantsWithIncrease}} receive an increase - {{totalParticipantsWithExceptions}} exceptions</span>

    </div>
    <div class="relative pt-1">
      <div class="cursor-pointer overflow-hidden h-4 mb-4 text-xs flex rounded-xl bg-teal-100">
        <div v-tippy="`${(100 - percentageParticipantsWithIncrease - percentageFullyApprovedParticipants).toFixed(2)}% no increase or pending`" :style="`width: ${100 - percentageParticipantsWithIncrease - percentageFullyApprovedParticipants}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-teal-100"></div>
        <div v-tippy="`${percentageParticipantsWithIncrease.toFixed(2)}% with a submitted but not approved increase`" :style="`width: ${percentageParticipantsWithIncrease}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-teal-300"></div>
        <div v-tippy="`${percentageFullyApprovedParticipants.toFixed(2)}% with approved increase`" :style="`width: ${percentageFullyApprovedParticipants}%;`" class="shadow-none flex flex-col text-center whitespace-nowrap justify-center bg-teal-500"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CycleSummary',
  props: {
    planners: Object,
    participants: Object,
    user: Object
  },
  computed: {
    filteredPlanners() {
      return this.planners.filter(planner =>
          this.participants.some(participant => String(participant.reports_to_eeid) === String(planner.eeid))
      );
    },
    totalPlanners() {
      return this.filteredPlanners.length;
    },
    totalPendingPlanners() {
      return this.filteredPlanners.filter(planner => planner.status == 'requested').length;
    },
    totalSubmittedPlanners() {
      return this.filteredPlanners.filter(planner => planner.status == 'submitted').length;
    },
    totalApprovedPlanners() {
      return this.filteredPlanners.filter(planner => planner.status == 'approved').length;
    },
    percentagePendingPlanners() {
      return (this.totalPendingPlanners / this.totalPlanners) * 100;
    },
    percentageSubmittedPlanners() {
      return (this.totalSubmittedPlanners / this.totalPlanners) * 100;
    },
    percentageApprovedPlanners() {
      return (this.totalApprovedPlanners / this.totalPlanners) * 100;
    },
    totalParticipants() {
      return this.participants.length;
    },
    totalParticipantsWithIncrease() {
      return this.participants.filter(participant => participant.total_percentage_increase > 0).length;
    },
    totalParticipantsWithExceptions() {
      return this.participants.filter(participant => {
        const hasIncrease = participant.total_percentage_increase > 0;
        const exceedsMaxRecommendation = participant.total_percentage_increase > (participant.max_recommendation + 0.4);
        const exceedsCompaRatio = participant.new_compa_ratio > 1.2;
        const variablePercentageChanged = hasIncrease && participant.current_variable_percentage != participant.new_variable_percentage;

        return exceedsMaxRecommendation || exceedsCompaRatio || variablePercentageChanged;
      }).length;
    },
    totalParticipantsWithApprovedPlanner() {
      return this.participants.filter(participant => {
        let planner = this.filteredPlanners.find(planner => planner.eeid == participant.reports_to_eeid);
        return planner && planner.status == 'approved';
      }).length;
    },
    percentageParticipantsWithIncrease() {
      return ((this.totalParticipantsWithIncrease - this.totalParticipantsWithApprovedPlanner) / this.totalParticipants) * 100;
    },
    percentageFullyApprovedParticipants() {
      return (this.totalParticipantsWithApprovedPlanner / this.totalParticipants) * 100;
    }
  }
}
</script>
