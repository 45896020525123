<template>
  <div id="multi-planner-sheet">
    <div class="m-3 flex gap-3">
      <div class="flex-item flex-col">
        <div>Worksheet Currency:
          <select v-model="worksheetCurrency" class="cursor-pointer border-gray-400 leading-tight text-sm">
            <option>Local Currency</option>
            <option>EUR</option>
          </select>
        </div>
      </div>
      <div class="flex-item flex-col">
        <div>
          Adjust benchmarks to PI:
          <select v-model="usePerformanceIndicator" class="cursor-pointer border-gray-400 400 leading-tight text-sm">
            <option>Yes</option>
            <option>No</option>
          </select>
        </div>
      </div>

      <div class="flex-item flex-col" v-if="ineligibleParticipants && ineligibleParticipants.length > 0">
        <div class="flex items-center">
          Include Ineligible Participants:
          <input type="checkbox" v-model="includeIneligibleParticipants" class="cursor-pointer border-gray-400 leading-tight text-sm">
        </div>
      </div>

      <div class="flex-item flex-col">
        <div class="flex items-center">
          <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-4.35-4.35m1.35-5.65a7 7 0 11-14 0 7 7 0 0114 0z"></path>
          </svg>
          <input type="text" v-model="searchQuery" placeholder="Search participants" class="ml-2 border-gray-400 leading-tight text-sm">
        </div>
      </div>
      <hr/>
    </div>

    <participant-table :nobody-has-variable-pay="nobodyHasVariablePay">
      <template v-slot:rows>
        <template v-for="participant in filteredParticipants">
          <ineligible-participant-detail :key="participant.eeid" v-if="participant.not_eligible"
                                         :participant="participant"
                                         :use-local-currency="useLocalCurrency"
                                         :adjust-benchmarks="usePerformanceIndicator"
                                         :nobody-has-variable-pay="nobodyHasVariablePay"
                                         :currency-rate="currencyForParticipant(participant)"
          />
          <eligible-participant-detail :key="participant.eeid" v-else
                                            :participant="participant"
                                            :use-local-currency="useLocalCurrency"
                                            :adjust-benchmarks="usePerformanceIndicator"
                                            :cycle="cycle"
                                            :editable="canEditParticipant(participant)"
                                            :nobody-has-variable-pay="nobodyHasVariablePay"
                                            :currency-rate="currencyForParticipant(participant)"
                                            :comments="commentsForParticipant(participant)"
                                            :mentionables="mentionables"
                                            :participant-submissions="submissionsForParticipant(participant)"
                                            @update-participant="updateParticipant"
          />
        </template>
      </template>
    </participant-table>
  </div>
</template>

<script>
import ParticipantTable from "@/components/compensation_review/ParticipantTable.vue";
import IneligibleParticipantDetail from "@/components/compensation_review/IneligibleParticipantDetail.vue";
import EligibleParticipantDetail from "@/components/compensation_review/EligibleParticipantDetail.vue";
import ParticipantMixin from '@/components/compensation_review/mixins/ParticipantMixin.js';

export default {
  name: "MultiPlannerSheet",
  components: { ParticipantTable, IneligibleParticipantDetail, EligibleParticipantDetail },
  mixins: [ParticipantMixin],
  props: {
    cycle: Object,
    planners: Array,
    participants: Array,
    user: Object,
    approvals: Object,
    comments: Object,
    currencies: Array,
    benchmarks: Array,
    mentionables: Array,
    participantSubmissions: Array,
    ineligibleParticipants: Array,
    currentPlanner: Object,
    departmentView: String
  },
  data() {
    return {
      worksheetCurrency: "Local Currency",
      usePerformanceIndicator: "No",
      includeIneligibleParticipants: false,
      searchQuery: ""
    }
  },
  computed: {
    useLocalCurrency() {
      return this.worksheetCurrency == "Local Currency"
    },
    nobodyHasVariablePay() {
      return this.participants.every(participant => participant.variable_pay == null || participant.variable_pay == 0.0)
    },
    filteredParticipants() {
      return this.allPossibleParticipants.filter(participant =>
          participant.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    canEditParticipant(participant) {
      if (this.user.roles.includes('admin') || this.user.roles.includes('coach_compensation_cycles')) {
        return true // these roles can always edit
      }

      const plannerForParticipant = this.planners.find(p => String(p.eeid) == String(participant.reports_to_eeid))
      if (plannerForParticipant.status == 'requested' && plannerForParticipant.eeid == this.user.eeid) {
        return true // the current user is the manager of the participant and has not submitted yet
      }

      if (plannerForParticipant.status == 'submitted') {
        const currentApproval = this.approvals.find(a => a.status == 'requested')
        if (currentApproval && currentApproval.approver_eeid == this.currentPlanner.eeid) {
          return true
        }
      }

      return false
    },
    updateParticipant(participant) {
      this.$emit('update-participant', participant)
    },
  }
}
</script>