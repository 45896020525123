<template>
  <div class="overflow-x-auto mt-7">
    <table class="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
      <thead class="ltr:text-left rtl:text-right">
      <tr class="bg-gray-100">
        <th class="sticky left-0 text-left bg-gray-100 whitespace-nowrap px-4 py-5 font-medium text-gray-700 uppercase font-semibold text-xs">Employee</th>
        <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Current Total Cash</th>
        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Current Compa</th>
        <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Benchmark</th>
        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">
          Suggested % Increase
          <svg v-tippy="tooltipContent" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 inline-flex items-baseline">
            <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
          </svg>
        </th>
        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">% Increase Base</th>
        <th v-if="!nobodyHasVariablePay" class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">% Increase Variable Pay</th>
        <th v-if="!nobodyHasVariablePay" class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Total % Increase</th>
        <th class="text-left whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">New Total Cash</th>
        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">New Compa</th>
        <th class="whitespace-nowrap px-4 py-2 font-medium text-gray-700 uppercase font-semibold text-xs">Actions</th>
      </tr>
      </thead>
      <tbody class="divide-y-2 divide-gray-200">
      <slot name="rows"></slot>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "ParticipantTable",
  props: {
    nobodyHasVariablePay: Boolean
  },
  data() {
    return {
      tooltipContent: {
        content: `Performance-based increase targets: <br/><ul><li>- Needs Improvement: 0%</li><li>- Partly Performing: 2%</li><li>- Fully Performing: 4%</li><li>- Exceeding: 6% or increase to at least the midpoint (1.0 CR), whichever is higher</li><br /> Where possible, look to align Camundi to their performance sub-range. Managers should make tradeoffs within budget based on team equality and performance.</></ul>`,
        allowHTML: true
      }
    }
  }
};
</script>