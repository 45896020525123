<template>
  <div class="w-screen mb-1.5" style="font-family: 'Plus Jakarta Sans'">
    <div class="w-5/6 justify-center mx-auto m-2" v-if="cycle && departmentalDetails">
      <p class="text-3xl mb-1.5 text-center font-bold">{{cycle.name}}</p>

      <div class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color w-full overflow-x-auto">
          <h2 class="font-semibold text-xl tracking-tight text-color mb-4">
            Budget
            <span class="font-normal text-sm">
              <a :href="overviewLink" class="hover:underline text-blaze-orange-600">Switch to cycle overview</a>
            </span>
          </h2>


          <table class="w-full table table-auto">
            <tr>
              <th class="text-left">Department</th>
              <th class="text-left">Original Budget</th>
              <th class="text-left">% to Original</th>
              <th class="text-left">Extra Budget</th>
              <th class="text-left">% including Extra</th>
              <th class="text-left">Total Spent</th>
              <th class="text-left">Remaining Budget</th>
              <th class="text-left">Compa Ratio Before</th>
              <th class="text-left">Compa Ratio After</th>
              <th class="text-left">Comments</th>
            </tr>

            <template v-for="department in departments">
              <tr>
                <td>{{department}}</td>
                <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(departmentBudgets[department][0].amount))}}</td>
                <td>{{Number(departmentBudgets[department][0].amount / departmentBudgets[department][0].payroll_amount * 100).toFixed(2)}}%</td>
                <td>
                  <span>€ {{new Intl.NumberFormat('en-US').format(departmentBudgets[department][0].extra_budget)}}</span>
                </td>
                <td>
                  <span v-if="departmentBudgets[department][0].extra_budget > 0">
                    {{Number((Number(departmentBudgets[department][0].amount) + Number(departmentBudgets[department][0].extra_budget)) / departmentBudgets[department][0].payroll_amount * 100).toFixed(2)}}%
                  </span>
                  <span v-else>{{Number(departmentBudgets[department][0].amount / departmentBudgets[department][0].payroll_amount * 100).toFixed(2)}}%</span>
                </td>

                <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(departmentBudgets[department][0].total_spent))}}</td>
                <td>€ {{new Intl.NumberFormat('en-US').format(Math.round(Number(departmentBudgets[department][0].amount) + Number(departmentBudgets[department][0].extra_budget) - Number(departmentBudgets[department][0].total_spent)))}}</td>

                <td>{{Number(departmentalDetails[department].median_compa_ratio).toFixed(2)}}</td>
                <td>{{Number(departmentalDetails[department].new_median_compa_ratio).toFixed(2)}}</td>
                <td>
                  <div class="text-gray-900 whitespace-no-wrap ml-3 text-left">
                    <span @click="displayModal(department)" class="inline-flex items-baseline cursor-pointer" v-if="departmentalDetails[department].comments && departmentalDetails[department].comments.length > 0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 self-center w-5 h-5 rounded-full mx-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                      </svg>
                    </span>

                    <span @click="displayModal(department)" class="inline-flex items-baseline cursor-pointer self-center w-5 h-5 rounded-full mx-1" v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                      </svg>
                    </span>
                  </div>
                </td>
              </tr>
            </template>

            <!-- Totals Row -->
            <tr class="mt-2 mb-2 border-t-1 border-gray-400">
              <td class="font-bold">Totals</td>
              <td class>€ {{new Intl.NumberFormat('en-US').format(Math.round(totals.total_original_budget))}}</td>
              <td class>{{Number(totals.total_original_budget / Number(totals.total_payroll) * 100).toFixed(2)}}%</td>
              <td class>€ {{new Intl.NumberFormat('en-US').format(totals.total_new_budget)}}</td>
              <td class>
                {{(((Number(totals.total_new_budget) + Number(totals.total_original_budget)) / Number(totals.total_payroll)) * 100).toFixed((2))}}%
              </td>
              <td class>€ {{new Intl.NumberFormat('en-US').format(Math.round(totals.total_spent))}}</td>
              <td class>€ {{new Intl.NumberFormat('en-US').format(Math.round(Number(totals.total_original_budget) + Number(totals.total_new_budget) - Number(totals.total_spent)))}}</td>
              <td>{{Number(totals.median_compa_ratio).toFixed(2)}}</td>
              <td>{{Number(totals.new_median_compa_ratio).toFixed(2)}}</td>
              <td >
                <div class="text-gray-900 whitespace-no-wrap ml-3 text-left">
                    <span @click="displayModal('totals')" class="inline-flex items-baseline cursor-pointer" v-if="departmentalDetails['totals'].comments && departmentalDetails['totals'].comments.length > 0">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 self-center w-5 h-5 rounded-full mx-1">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                      </svg>
                    </span>

                  <span @click="displayModal('totals')" class="inline-flex items-baseline cursor-pointer self-center w-5 h-5 rounded-full mx-1" v-else>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.76c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.076-4.076a1.526 1.526 0 0 1 1.037-.443 48.282 48.282 0 0 0 5.68-.494c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
                      </svg>
                    </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="mt-4 p-1">
        For a detailed report of Camundi increases and the final pay equality analysis, please see the following <a :href="cycle.pay_equality_dashboard_url" class="hover:underline text-blaze-orange-600" target="_blank">workbook</a>. In the next iteration of CamundiPay, we plan to incorporate these metrics directly in this dashboard.
      </div>


      <div class="mt-2 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mt-5">
        <div class="backdrop-blur-sm p-6 rounded-xl hover:border-1 transition-colors duration-300 overflow-hidden border border-color w-full overflow-x-auto">

          <table class="w-full table  table-auto">
            <tr>
              <th class="text-left">Approver</th>
              <th class="text-left">Status</th>
              <th class="text-left">Approved At</th>
              <th class="text-left">Action</th>
            </tr>

            <template v-for="approver in approvals">
              <tr>
                <td>{{approver.name}}</td>
                <td>{{approver.approval.status}}</td>
                <td>{{approver.approval.approved_at}}</td>
                <td>
                  <div v-if="approvalForUser(approver) && approver.approval.status == 'requested'">
                    <button class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded" @click="approve(approver.approval.id)">
                      Approve Compensation Cycle
                    </button>
                  </div>
                  <div v-else>
                    N/A
                  </div>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
  </div>


  <div v-if="showModal && currentDepartment">
    <CommitteeComments
        :comments="departmentalDetails[currentDepartment].comments"
        :department="currentDepartment"
        :cycle-id="cycle.id"
        :mentionables="mentionables"
        @close="closeModal"
    />
  </div>
</template>

<script>

import CommitteeComments from './CommitteeComments.vue'
export default {
  name: 'CompensationCommittee',
  components: {
    CommitteeComments
  },
  props: {
    cycleId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      cycle: null,
      sourceData: null,
      departmentalDetails: null,
      departments: [],
      approvals: [],
      user: null,
      comments: null,
      showModal: false,
      currentDepartment: false,
      mentionables: [],
      departmentBudgets: [],
      totals: []
    }
  },
  mounted() {
    this.getSourceData()
  },
  computed: {
    overviewLink() {
      return `/compensation_cycles/${this.cycle.id}/comp_committee_full_show`
    }
  },
  methods: {
    async getSourceData() {
      await fetch('/compensation_cycles/comp_committee_data.json?id=' + this.cycleId, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin'
      })
          .then(response => response.json())
          .then(data => {
            this.setData(data)
          })
    },
    setData(data) {
      this.sourceData = data
      this.cycle = data.compensation_cycle
      this.departments = data.departments
      this.departmentalDetails = data.departmental_details
      this.approvals = data.approvals
      this.user = data.user
      this.mentionables = data.mentionables
      this.departmentBudgets = data.department_budgets
      this.totals = data.totals
    },
    approvalForUser(approver) {
      return approver.approval.user_id == this.user.id
    },
    approve(id) {
      console.log(id)
      fetch(`/compensation_cycles/${this.cycle.id}/cycle_committee_approvals/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        },
        credentials: 'same-origin',
        body: JSON.stringify({
          cycle_committee_approval: {status: 'approved', approved_at: new Date()}
        })
      }).then(response => response.json())
          .then(data => {
            this.getSourceData()
          })
    },
    displayModal(department) {
      this.currentDepartment = department
      this.showModal = true
    },
    closeModal() {
      this.getSourceData()
      this.currentDepartment = null
      this.showModal = false
    }
  }
}
</script>
